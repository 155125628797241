
#menu {
	background: #9E0910;
	color: #BDB9A6;
	border: 0;
	margin-bottom: 15px;
	border: 0;
	margin: 0;
}
nav#menu .nav {
	width: 100%;
}
#menu #search {
	position: absolute;
	top: 0;
	right: 0;
	padding-right: 15px;
	height: 50px;
	background: transparent;
	/*padding-left: 15px;*/
	height: 100%;
}
#menu #search form {
	position: relative;
	height: 100%;
}
#menu #search form button {
	position: absolute;
	top: 0px;
	right: 0px;
	color: rgba(255, 255, 255, 0.8);
	width: 40px;
	height: 100%;
	border: 0;
	background: transparent;
	text-align: center;
	/*line-height: 28px;*/
	padding: 0;
	font-size: 18px;
}
#menu #search form button.left_submit {
	left: 0;
	display: none;
}
#menu #search form a {
	float: right;
	color: #ffffff;
	font-size: 16px;
	margin-left: 10px;
	padding-top: 12px;
	height: 100%;
}
#menu #search input{
	width: 0;
	height: 100%;
	padding: 0;
	line-height: 30px;
	background: #6F060B;
	color: #ffffff;
	border: 0;
}
#menu #search input:focus{
	background: #6F060B;
}
.font-strong {
	font-family: 'Dangrek', Arial, Helvetica, sans-serif;
}
#menu.navbar-inverse .navbar-nav > li > a {
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Dangrek', Arial, Helvetica, sans-serif;
  font-size: 20px;
  position: relative;
}
#menu.navbar-inverse .navbar-nav > li > a > img {
  height: 50px;
  margin-top: -21px;
  margin-bottom: -20px;
}
#menu.navbar-inverse .navbar-nav > li > a:hover{
  color: #fff;
  @include transition(.3s);
}

#menu.navbar-inverse .navbar-nav > li.active > a {
  background: #D90C16;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

nav#menu .nav > li.more {
	display: none;
}

.mobile-menu {
	display: none;
}

@media (max-width: 767px) {
	.label-news-feed {
		text-align: center;
		line-height: 50px;
		
		a {
			color: #fff;
			font-size: 17px;
			font-family: Dangrek,Arial,Helvetica,sans-serif;
			// padding-left: 20px;
			// float: left;
			// width: 100%;
		}

		a:first-child() {
			padding-right: 20px;
			border-right: 2px solid red;
			margin-right: 15px;
		}
	}

	.news-feed-filter {
		text-align: right;
		
		a {
			font-size: 18px;
			color: #8a887a;
			line-height: 50px;
			// padding-right: 15px;
			padding: 4px 8px;
			background: #272626;
			border-radius: 4px;
			padding-bottom: 3px;
		}

		a.expand-burger-menu-filter {
			padding-bottom: 11px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.news-feed-menu {
		margin-left: -15px;
		margin-right: -15px;
		display: none;
		background: #272525;
		width: 100%;
		float: right;
		text-align: center;
		padding-right: 15px;
	}

	.icon-news-feed {
		text-align: center;
		line-height: 49px;
		
		a {
			padding-left: 35px;
			color: #fff;
			font-size: 22px;
		}

		a:first-child() {
			position: relative;
			top: 1px;
		}
	}

	.special-report li {
		display: inline;

		img {
			max-width: 100%;
			height: auto;			
		}
	}

	.special-report li.ads-spacing img {
		margin-top: 10px;
	}

	// .navbar-collapse.navbar-ex1-collapse {
	// 	max-height: 400px;
	// }

	.break-section {
		margin: 0;
		border: 0;
		border-top: 1px solid #8c8c8c;
	}	
	.burger-toggle-menu {
		position: relative;
		height: 35px;
		margin-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		.burger-menu {
			margin-top: -8px;
		}
	}

	.navbar-brand h1 {
		font-family: Dangrek,Arial,Helvetica,sans-serif;
		text-transform: uppercase;
	}

	.navbar-ex1-collapse {
		background: #272626;
	}

	.navbar-header.page-scroll{
		height: 70px;
	}

	.navbar-toggle {
		background-color: #272626;
		margin-top: 19px;		 

		.burger-menu .menu-label {
			float: right;
			position: absolute;
			top: 5px;
			right: 9px;
			font-weight: bolder;
			color: #999999;
		}
	}	

	.desktop-menu {
		display: none;
	}

	.mobile-menu {
		display: block;		
	}

	.suggested-app, .special-report {
		margin-top: 17px;
		padding-left: 0;
		text-align: center;
		margin-bottom: 17px;

		label {
			font-size: 17px;
			text-transform: capitalize;
			color: #c8cecd !important;
			font-weight: lighter;
			margin-bottom: 17px;
			font-family: Dangrek,Arial,Helvetica,sans-serif !important;

			span {
				font-weight: bold;
			}
		}	
	}

	.suggested-app {
		
		li {
			display: inline;
		}

		li a {
			font-family: Dangrek,Arial,Helvetica,sans-serif !important;
		}

		a {
			color: #999999;
			font-size: 17px;
			padding: 0 13px 0 19px;
			border-right: 2px solid #fa1939;
		}

		li:last-child a {
			border-right: none;
		}		
	}

	#menu.navbar-inverse .navbar-nav.mobile-menu {
		margin-top: 17px;

		label, li a {
			font-family: Dangrek,Arial,Helvetica,sans-serif !important;
			font-size: 17px;
			text-transform: uppercase;
			color: #999999 !important;
		}		

		li:last-child a {
			margin-bottom: 15px;
		}
	}

	.menu-fix {
		top: 26px;
		z-index: 999;
		width: auto;
		background: #272525;
		padding: 7px 16px;
		margin-top: 0;
		margin-left: -15px;
		margin-right: -15px;
		padding-top: 5px;
		border-top: 1px solid #716d6d;

		.menu-collapse {
			overflow-y: scroll;
			width: 93%;
			height: 30px;
			line-height: 35px;
		}

		.menu-collapse::-webkit-scrollbar {
			height: 0;
			width: 0;
		}

		ul.menu-items {
			padding-left: 0;
			margin-bottom: 0;
			height: 30px;
			width: auto !important;

			li {
				display: inline;								
				position: relative;

				a {
					padding-left: 18px;
					padding-right: 12px;
					color: #fff;
					font-size: 17px;
					border-right: 2px solid #fa1939;
					font-family: Dangrek,Arial,Helvetica,sans-serif;
				}

				a:focus, a:hover {
					text-decoration: none;
				}				
			}

			li.active a {
				color: #fa1939;
			}

			li:first-child() a{
				padding-left: 0;
			}

			li:last-child() a{
				border-right: none;
			}
		} 

		.more-menu-item {
			float: right;
			margin-top: -23px;
			line-height: 19px;
			font-size: 17px;
			color: #fff;

			span {
				margin-right: 10px;								
			}

			i {
				color: #fa1939;
				font-size: 17px;
				position: relative;
				top: 1px;
			}
		}
	}
	#menu {
		margin: 0;
		background: #161616;
	}
	nav#menu .nav {
		width: auto;
	}
	#menu .container {
		padding: 0 15px;
	}
	#menu #search {
		background: #161616;
		height: 50px;
	}
	#menu #search input{
		width: 0px;
		padding: 0;
	}
	#menu #search form button {
		color: #ffffff;
		cursor: pointer;
	}
	.mean-content {
		margin-top: 0px;
	}
	.navbar-nav {
		margin: 0;
		margin-left: -15px;
		margin-right: -15px;
		padding: 0;
	}
	#menu .nav.navbar-nav li a img{
		width:auto;
		height:40px;
	}
}

@media (max-width: 736px) {
	#menu.navbar {
		min-height: auto;	

		.navbar-header.page-scroll {
			height: 45px;
		}
		.navbar-brand {
			margin-top: 3px;
			height: 40px;

			img {
				height: 30px;
			}

			h1 {
				font-size: 17px;
				margin-top: -22px;
				margin-left: 38px;
				color: #fa1939;
			}
		}

		.navbar-toggle {
			padding: 7px 8px;
			margin-top: 10px;

			.icon-bar + .icon-bar {
				margin-top: 3px;
			}

			.icon-bar {
				width: 17px;
			}
		}
	}
}

@media (max-width: 320px) {
	.menu-fix .more-menu-item span {
		margin-right: 5px;
	}

	.navbar-toggle .burger-menu {
		padding-right: 0;

		.menu-label {
			display: none;
		}
	}
}